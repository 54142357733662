
    var riot = require('riot')
    
riot.tag2('tags_input', '<div class="current-tags"> <div class="col-sm-2"> <h5>Current Tags</h5> </div> <div class="col-sm-10"> <p if="{currentTags.length == 0}">None found</p> <p> <div each="{tag in currentTags}" onclick="{parent.removeTag.bind(null,tag)}" class="badge">{tag.label} <i class="fa fa-times remove"></i></div> </p> </div> </div> <div class="form"> <div class="form-group"> <div class="control-label col-xs-2">Add Tag</div> <div class="col-xs-10 col-sm-4"> <div class="input-group"> <input name="add_label_input" class="form-control"> <div class="input-group-btn"> <button type="submit" onclick="{addTag}" class="btn btn-primary">Add</button> </div> </div> </div> </div> </div> <div class="all-tags"> <div class="col-sm-2"> <h5>All Tags</h5> </div> <div class="col-sm-10"> <p if="{allTags.length == 0}">None found</p> <p> <div each="{tag in allTags}" onclick="{parent.useTag.bind(null, tag)}" class="badge">{tag.label} <i class="fa fa-plus add"></i></div> </p> </div> </div>', 'tags_input .form { margin: 20px 0; } tags_input .all-tags, tags_input .current-tags { overflow: hidden; } tags_input .all-tags .badge, tags_input .current-tags .badge { cursor: pointer; margin-right: 5px; } tags_input .all-tags .badge:hover .add { display: inline; } tags_input .all-tags .badge .add { display: none; } tags_input .current-tags .badge:hover .remove { display: inline; } tags_input .current-tags .badge .remove { display: none; }', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/tags', true);

this.on('mount', function() {
  this.getAllTags();
  return this.getCurrentTags();
});

this.getAllTags = (function(_this) {
  return function() {
    return api.get(function(data) {
      _this.allTags = data;
      return _this.update();
    });
  };
})(this);

this.getCurrentTags = (function(_this) {
  return function() {
    return api.get({
      id: opts.page_id
    }, function(data) {
      _this.currentTags = data;
      return _this.update();
    });
  };
})(this);

this.useTag = (function(_this) {
  return function(tag) {
    var newTag;
    newTag = {
      page_id: opts.page_id,
      label: tag.label
    };
    return api.save(newTag, function(data) {
      _this.currentTags.push(data);
      return _this.update();
    });
  };
})(this);

this.addTag = (function(_this) {
  return function() {
    var newTag;
    newTag = {
      page_id: opts.page_id,
      label: _this.add_label_input.value
    };
    return api.save(newTag, function(data) {
      _this.add_label_input.value = '';
      _this.currentTags.push(data);
      _this.getAllTags();
      return _this.update();
    });
  };
})(this);

this.removeTag = (function(_this) {
  return function(tag) {
    return api.remove({
      id: tag.id
    }, function() {
      _this.currentTags = _this.currentTags.filter(function(t) {
        return tag.id !== t.id;
      });
      _this.getAllTags();
      return _this.update();
    });
  };
})(this);
});
    
  