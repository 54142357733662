
    var riot = require('riot')
    
riot.tag2('blogselector', '<div class="flex"> <ul class="list-unstyled list-inline blogfilters"> <li onclick="{showall}" class="filterbutton {activetag==\'\'?\'active\':\'\'}">All</li> <li each="{tag in allTags}" onclick="{parent.filterList.bind(null,tag)}" class="filterbutton {activetag==tag?\'active\':\'\'}">{tag.label}</li> </ul> </div>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/tags', true);

this.bloglist = document.querySelector('.dl_blog');

this.activetag = '';

this.on('mount', function() {
  return this.getAllTags();
});

this.getAllTags = (function(_this) {
  return function() {
    return api.get(function(data) {
      _this.allTags = data;
      return _this.update();
    });
  };
})(this);

this.filterList = (function(_this) {
  return function(tag) {
    var boxes, i, tagstr;
    tagstr = tag.label.toLowerCase();
    _this.activetag = tag;
    if (_this.bloglist) {
      boxes = _this.bloglist.querySelectorAll('.box');
      i = 0;
      while (i < boxes.length) {
        boxes[i].classList.remove('hideme');
        if (!boxes[i].classList.contains(tagstr)) {
          boxes[i].classList.add('hideme');
        }
        i++;
      }
    }
    return _this.update();
  };
})(this);

this.showall = (function(_this) {
  return function() {
    var boxes, i;
    _this.activetag = '';
    if (_this.bloglist) {
      boxes = _this.bloglist.querySelectorAll('.box');
      i = 0;
      while (i < boxes.length) {
        boxes[i].classList.remove('hideme');
        i++;
      }
    }
    return _this.update();
  };
})(this);
});
    
  