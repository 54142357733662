
    var riot = require('riot')
    
riot.tag2('gdpr', '<modal onclose="{close}"> <div class="gdpr"> <h3>At Media and Digital, your privacy is important to us.</h3> <p>Please tell us what information you\'re willing to share with us and our partners.</p> <gdpr-toggle title="Marketing" group-id="7" user="{parent.opts.user}">Allow us to use your name and email address to send you offers we think you might be interested in</gdpr-toggle> <p>We use cookies to help create a great online experience.</p> <p>By continuing to use our website you accept that cookies may be stored on your device. It’s standard stuff, but if you’d like to know more click <a href="/privacy_policy/" target="_blank">Privacy Policy</a>or <a href="/terms_and_conditions/" target="_blank">Terms &amp; Conditions </a></p> <label> <input type="checkbox" onclick="{parent.dontShowAgain}">&nbsp; Don\'t show this again </label> </div> </modal>', '.gdpr input[type="checkbox"] { display: inline-block; } .gdpr .checkbox label { color: #000; }', '', function(opts) {
this.on('mount', (function(_this) {
  return function() {
    if (window.localStorage.gdprconsent === 'true') {
      return;
    }
    return _this.tags.modal.show();
  };
})(this));

this.close = function() {};

this.dontShowAgain = function(e) {
  return window.localStorage.gdprconsent = e.target.checked;
};
});
riot.tag2('gdpr-toggle', '<h5 class="title">{opts.title}</h5> <div class="checkbox"> <label> <input type="checkbox" onclick="{toggleGroup}" __checked="{checked}"><yield></yield> </label> </div>', 'gdpr-toggle { background: rgba(0,0,0,0.2); display: block; padding: 5px 20px; margin-bottom: 15px; }', '', function(opts) {
var resource, userApi;

resource = require('riot-kit/utils/apiResource.coffee');

userApi = new resource('/duocms/api/users/me/group', true);

this.on('mount', function() {
  this.checked = this.opts.user.groups.filter(function(g) {
    return g.id === +opts.groupId;
  }).length > 0;
  return this.update();
});

this.toggleGroup = function(e) {
  if (e.target.checked) {
    return userApi.save({
      id: opts.groupId
    }, (function(_this) {
      return function() {};
    })(this));
  } else {
    return userApi.remove({
      id: opts.groupId
    }, (function(_this) {
      return function() {};
    })(this));
  }
};
});
    
  